import React, { useState } from "react";
import cx from "classnames";
import Link from "next/link";
import PropTypes from "prop-types";
import { Row, Card, Image, Col } from "react-bootstrap";
import ChatCard from '@/components/home/Member/ChatCard';
import Modal from "@/components/Modal";
import { PODERCARD_LINK } from '@/env';
import { Rewards, ChevronRight } from '@/icons';
import { Dental, Telemedicine } from '@/icons/premium';
import { PODERSALUD_PRODUCT_ID } from '@separate/constants/analytics';
import { useTranslation } from '@separate/hooks/translation';
import useWindowSize from "@separate/hooks/useWindowSize";
import { asCurrency } from '@separate/utilities/string';
import styles from "./member.module.scss";
import SummaryCard from "./SummaryCard";

export default function MembershipDashboard(props) {
  const { user, membershipLevel, totalEarnings, isLoading, surveyListIsLoading} = props;
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const isOnDesktop = useWindowSize();
  const showMobileChatCard = !isLoading && user?.is_premium_member && !isOnDesktop;

  const handleClose = () => setShow(false);

  return (
    <Col className={cx(styles.membershipDashboardWrapper, "mb-4")}>
      <Card className={styles.membershipDashboard}>
        <Card.Body className={styles.cardBody}>
          {showMobileChatCard && (
            <Row className={`${styles.row} mb-4`}>
              <ChatCard className={styles.mobileChatCard} membershipLevel={membershipLevel} />
            </Row>
          )}

          {/* My Rewards */}
          <SummaryCard
            title={t('home.membership_dashboard.rewards')}
            subtitle={t('home.membership_dashboard.share_your_opinion')}
            linkText={t('home.membership_dashboard.go_to_rewards')}
            link='/earn'
            showHorizontalLine={user?.is_premium_member || user?.is_podercard_user}
          >
            <SummaryCard.label>
              {t('home.membership_dashboard.your_earnings')}
            </SummaryCard.label>
            <SummaryCard.value>
              <Rewards className='me-1' />
              {surveyListIsLoading ? `$--.--` : asCurrency(totalEarnings)}
            </SummaryCard.value>

            <SummaryCard.label>
              {t('home.membership_dashboard.course_completed')}
            </SummaryCard.label>
            <SummaryCard.value>
              {user?.courses_completed_count}
            </SummaryCard.value>
          </SummaryCard>

          {/* My Benefits */}
          {!isLoading && user?.is_premium_member && (
            <SummaryCard
              title={t('home.membership_dashboard.my_benefits')}
              subtitle={t('home.membership_dashboard.save_with_podersalud')}
              linkText={t('home.membership_dashboard.view_all_benefits')}
              link='/benefits'
              showHorizontalLine={user?.is_podercard_user}
            >
              <SummaryCard.label>
                <Dental className='me-1' />
                {t('home.membership_dashboard.dental')}
              </SummaryCard.label>
              <SummaryCard.value>
                <Link href='/benefits/dental'>
                  <a className={styles.link}>
                    {t('home.membership_dashboard.get_discounts')} <ChevronRight />
                  </a>
                </Link>
              </SummaryCard.value>

              <SummaryCard.label>
                <Telemedicine className='me-1' style={{ strokeWidth: 0.4 }} />
                {t('home.membership_dashboard.telemedicine')}
              </SummaryCard.label>
              <SummaryCard.value>
                <Link href='/benefits/rx'>
                  <a className={styles.link}>
                    {t('home.membership_dashboard.get_discounts')} <ChevronRight />
                  </a>
                </Link>
              </SummaryCard.value>
            </SummaryCard>
          )}

          {/* PODERcard */}
          {!isLoading && user?.is_podercard_user && (
            <SummaryCard
              title={t('home.membership_dashboard.podercard')}
              linkText={t('home.membership_dashboard.go_to_podercard_app')}
              link={PODERCARD_LINK}
            >
              <SummaryCard.label>
                {t('home.membership_dashboard.debit_card_holder')}
              </SummaryCard.label>
              <SummaryCard.value>
                <Image
                  src='https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blta32087be1d28ef8d/622aa12c2f117523f14ae6c1/debit.png'
                  width={100}
                  alt='card'
                />
              </SummaryCard.value>
            </SummaryCard>
          )}

          {/* Subscription */}
          {!isLoading && !user?.is_premium_member && (
            <SummaryCard
              title={t('home.membership_dashboard.poder_salud')}
              linkText={t('home.membership_dashboard.signup_for_podersalud')}
              linkButton='/premium'
              analyticsProperties={{
                product_id: PODERSALUD_PRODUCT_ID,
                logged_in: "true",
                cta_id: "member_dashboard",
              }}
            >
              <SummaryCard.label>
                {t('home.membership_dashboard.unsubscribed')}
              </SummaryCard.label>
              <SummaryCard.value>
                {t('home.membership_dashboard.not_available')}
              </SummaryCard.value>
            </SummaryCard>
          )}
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleClose}>
        <div className={styles.modal}>
          <p> {t('premium.appointment.book_appointment_instruction')} </p>
          <p>
            <strong> 726-202-1213 </strong>
          </p>
        </div>
      </Modal>
    </Col>
  );
}

MembershipDashboard.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  membershipLevel: PropTypes.string,
  totalEarnings: PropTypes.number,
  isLoading: PropTypes.bool,
  surveyListIsLoading: PropTypes.bool,
};
